import React from 'react'

import Box from 'components/Box'
import Button from 'components/Button'
import Link from 'components/Link'
import TableModule from 'components/TableModule'

const theaderData = [
  {
    title: '問卷名稱',
    key: 'title',
    py: '1.5em',
    textAlign: 'center',
  },
  {
    title: '填寫連結',
    key: 'id',
    Comp: Box,
    px: '2em',
    textAlign: 'center',
    render: (children) => <Button as={(p) => <Link color="white" {...p} />} to={`/survey/do/${children}`}>開始填寫</Button>,
  },
  {
    title: '開始日期',
    key: 'showDateFrom',
    textAlign: 'center',
  },
  {
    title: '最後填寫日期',
    key: 'showDateTo',
    textAlign: 'center',
  },
]

const DesktopOffices = ({ data, note, ...props }) => (
  <TableModule theaderData={theaderData} data={data} note={note} {...props} />
)

export default DesktopOffices
