import React from 'react';

import Data from 'contexts/data'
import useResponsive from 'contexts/mediaQuery/useResponsive'

import Mobile from './MobileIndex'
import Desktop from './DesktopIndex'

const SurveyPage = () => {
  const { isMobile } = useResponsive()
  const note = '目前無可供填寫的問卷'
  return (
    <Data path="/survey">
      {(data) => data && (isMobile ?
        <Mobile note={note} data={data} /> : <Desktop data={data} note={note} />
      )}
    </Data>
  )
};

export default SurveyPage;
