import React from 'react'

import Button from 'components/Button';
import Box from 'components/Box'
import Link from 'components/Link'
import TableMobileModule from 'components/TableMobileModule'

const keyData = [
  {
    title: '問卷名稱',
    key: 'title',
    fontSize: '1.25em',
    fontWeight: 'bold',
  },
  {
    title: '填寫連結',
    key: 'id',
    Comp: Box,
    py: '1em',
    render: (children) => <Button as={(p) => <Link color="white" {...p} />} to={`/survey/do/${children}`}>開始填寫</Button>,
  },
  {
    key: 'showDateFrom',
    render: (children) => `開始日期：${children}`
  },
  {
    key: 'showDateTo',
    render: (children) => `結束填寫日期：${children}`
  },
]

const MobileOffices = ({ data, note, ...props }) => (
  <TableMobileModule data={data} keyData={keyData} note={note} {...props} />
)

export default MobileOffices
