import React from 'react';

import ContentLayout from 'components/ContentLayout'

import Page from 'containers/SurveyPage'

export default () => (
  <ContentLayout title="問卷調查">
    <Page />
  </ContentLayout>
)
